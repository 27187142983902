@charset "utf-8";
html{scroll-padding-top:115px;scroll-behavior: smooth;}
:root {
--cm-blue:#214665;
--cm-indigo:#6610f2;
--cm-purple:#6f42c1;
--cm-pink:#d63384;
--cm-red:#ef4136;
--cm-orange:#f57224;
--cm-yellow:#ffc107;
--cm-green:#63F79F;
--cm-teal:#20c997;
--cm-cyan:#0dcaf0;
--cm-white:#ffffff;
--cm-black:#000;
--cm-gray:#6c757d;
--cm-gray-dark:#263746;
--cm-primary:#0d6efd;
--cm-secondary:#6c757d;
--cm-success:#198754;
--cm-info:#0dcaf0;
--cm-warning:#ffc107;
--cm-danger:#dc3545;
--cm-light:#f8f9fa;
--cm-dark:#212529;
--cm-font-sans-serif:"Montserrat", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
--cm-font-monospace:"Montserrat", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
--cm-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
--cm-transition:.5s linear;
--cm-background:#fff;
--cm-font-size:1.125rem;
--cm-border-radius:5px;
--cm-box-shadow:0 0 10px #ccc;
}

@font-face{font-family:'Cera-Bold';src:url(../fonts/CeraPro-Bold.woff2) format('woff2'), url(../fonts/CeraPro-Bold.woff) format('woff');font-weight:700;font-style:normal;font-display:swap;}
@font-face{font-family:'Cera-Light';src:url(../fonts/CeraPro-Light.woff2) format('woff2'), url(../fonts/CeraPro-Light.woff) format('woff');font-weight:300;font-style:normal;font-display:swap;}
@font-face{font-family:'Cera-Regular';src:url(../fonts/CeraPro-Regular.woff2) format('woff2'), url(../fonts/CeraPro-Regular.woff) format('woff');font-weight:400;font-style:normal;font-display:swap;}

body{background:#F0F0F0 !important;font-family:'Cera-Regular' !important;font-size:var(--cm-font-size)!important;font-weight:400;line-height:1.5 !important;overflow-x:hidden;transition:var(--cm-transition);
color:var(--cm-black);}
body.home-bg{background:#000}
.img-responsive{width:100%;height:auto}
a{color:var(--cm-orange);transition:var(--cm-transition);outline:none;text-decoration:none !important}
a:hover{color:var(--cm-blue);}
.graph-responsive{width:100%;height:auto}
.align-center{text-align:center}

p:empty{display:none}

.clear,.clr{clear:both;height:1px;overflow:auto}
h1,h2,h3,h4,h5,h6{padding:0;margin:0;color:var(--cm-black);font-family:'Cera-Bold' !important;}
.dark-gray{color:#666;}
ul,ol{list-style:none}
ul,ol,p{margin:0;padding:0;}
ul li,ol li{margin:0;padding:0}
.align-right{text-align:right}
.relative{position:relative}
.dis-none{display:none}

::placeholder{color:#ccc;font-weight:400 !important;opacity:1;transition:transform 250ms, opacity 400ms;}
:focus::placeholder,
:hover::placeholder{opacity:0;}

.navigation{position:fixed;width:100%;z-index:999;transition:var(--cm-transition);background:rgba(0,0,0,.95);}
.top-bg{padding:0;width:98%;margin:auto;position:relative;transition:var(--cm-transition);}
.navigation .header{padding:.5rem 5%;border-bottom:1px solid #474a50;clear:both}
.navigation .header img{width:300px;height:auto}

.navigation .header span{text-transform:uppercase;padding-left:10px}
.navigation .header span a{color:var(--cm-white);font-weight:bold}
.navigation .header span a:hover{color:var(--cm-orange);}

.navigation .header div.logout{margin-top:0;position:relative;text-align:center}
.navigation .header div.logout i{display:inline-block;background:url(../images/icon-user.svg) center no-repeat;width:20px;height:20px;background-size:cover;vertical-align:middle;margin-top:-3px;margin-left:12px; position:relative;cursor: pointer;}
.navigation .header div.logout i:after{content:'';position:absolute;height:24px;width:1px;left:-8px;top:-2px;background-color:#ffbc94;}
.navigation .header div.logout a{color:var(--cm-orange);}
.navigation .header div.logout a:hover{color:var(--cm-white)}
.homeorder{margin-right: 20px;}
.dropdown-content{position:absolute;right:0px;top:35px;padding:0 1rem 1rem 1rem;box-shadow:0 .5rem 1rem rgba(0,0,0,.15);border-radius:0 0 5px 5px;background:rgba(0,0,0,.95);}
.dropdown-content a{display:inline-block;padding:5px 0px;font-weight:400 !important}

.header-left{float:left;text-align:left;width:100px}
.header-left img{width:75px;height:auto;margin:5px 0 5px -5px;}
.header-right{float:right;text-align:right;margin:0;width:calc(100% - 100px);position:relative}
.header-right ul{margin:0;padding:0;}

.menu-click{position:absolute;top:1.5rem;right:5px;z-index:9999;cursor:pointer}
.menu-click .bar1, .menu-click .bar2, .menu-click .bar3{width:25px;height:2px;background-color:#fff;margin:6px 0;transition:var(--cm-transition);display:block}
.menu-click.on .bar1{-webkit-transform:rotate(-45deg) translate(-9px, 6px);transform:rotate(-45deg) translate(-4px, 6px);background-color:#dc3545;}
.menu-click.on .bar2{opacity:0;}
.menu-click.on .bar3{-webkit-transform:rotate(45deg) translate(-8px, -8px);transform:rotate(45deg) translate(-6px, -7px);background-color:#dc3545;}

.menu-container{display:none;position:fixed;left:0;top:0;width:100%;height:calc(100vh);background-color:rgba(0,0,0,.95);line-height:normal;animation:fadeIn 1s linear;z-index:999;}
.menu-container ul{list-style:none;text-align:center;position:relative;top:50%;transform:translateY(-50%);}
.menu-container li{display:block;padding:1rem 0}
.menu-container li a{color:var(--cm-white);font-size:1.25rem;text-transform:uppercase}
.menu-container li a:hover{color:var(--cm-orange);}

.social-link{position:absolute;top:.25rem;right:3.5rem;z-index:99;transition:var(--cm-transition);}
.social-link a{display:inline-block;width:30px;height:30px;text-align:center;border-radius:50%;border:2px solid #fff;vertical-align:middle;line-height:22px;margin-right:0}
.social-link a:last-child{margin-bottom:0}
.social-link a img{vertical-align:middle}
.social-link a:hover{background:#f05929}

.social-link span{display:inline-block;width:30px;height:30px;text-align:center;vertical-align:middle;line-height:22px;margin-right:0}

.mailing{position:relative;display:block;color:#fff;margin-bottom:6px;}

.mailing .mail-box{background:none;position:absolute;width:155px;right:40px;top:2px;padding:0 !important;font-size:15px;border-radius:25px;transition:all .5s cubic-bezier(0.65, 0, 0.076, 1);z-index:999;color:#fff;padding:2px 10px !important;outline:none;border:2px solid #fff;}
.mailing.active-mail .mail-box{width:155px;padding:2px 10px !important;outline:none;border:2px solid #fff;}

.BTN-subscribe{cursor:pointer}
.mailing.active-mail .BTN-subscribe{background:#f05929}

.mailing .mail-box::placeholder{color:#fff;font-weight:400 !important;opacity:1;transition:transform 250ms, opacity 400ms;}
.mailing .mail-box:focus::placeholder,
.mailing .mail-box:hover::placeholder{opacity:0;}

.block-one{background:url(../images/block-one.png) 80% 100% no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff}
.block-one h1{color:#fff;padding:5rem 0 0 0;font-size:2rem}
.block-one h2{color:#fff;padding:1rem 0}
.block-one .relative{height:100vh}
.block-one .relative:before{background-image:linear-gradient(#000,#000);content:"";height:100%;width:100%;position:absolute;top:0;left:0;opacity:.65;}

.block-copyright{background-color: #272b36; color:#fff; padding-bottom:2rem;}
.block-copyright h1{color:#fff;padding:4rem 0 1rem 0;font-size:2rem}
.block-copyright h2{color:#fff;padding:1rem 0}
.block-copyright a:hover{color:#fff}
.block-two{background:url(../images/block-two.jpg) 25% 100% no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff;background-attachment:fixed;text-align:center}
.block-two h2{padding-top:5vh;color:#fff;font-size:3rem;}
.block-two h3{-webkit-text-stroke:#fff 1px;color:transparent;font-size:2rem;padding-bottom:1rem} 
.block-two video{border-radius:10px;box-shadow:0 0 10px #000;height:400px;width:auto}

.block-three{background:url(../images/block-three.jpg) top center no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff;background-attachment:fixed;text-align:center}
.block-three .align-center{padding-top:70vh}
.block-three h2{-webkit-text-stroke:#fff 1px;color:transparent;font-size:3rem;padding-bottom:.5rem;}
.block-three h3{color:#fff;font-size:1.5rem;}

.block-four{background:url(../images/block-4.png) 85% 100% no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff;background-attachment:fixed;text-align:center}
.block-four .align-center{padding-top:70vh}
.block-four h2{color:#fff;font-size:2.25rem;padding-bottom:.5rem;}
.block-four h3{-webkit-text-stroke:#fff 1px;color:transparent;font-size:3rem;}

.block-five{background:url(../images/block_five.png) 85% 100% no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff;background-attachment:fixed;text-align:left}
.block-five h2{color:#fff;font-size:1.5rem;letter-spacing:1px;padding-top:75vh;}
.block-five h3{-webkit-text-stroke:#fff 1px;color:transparent;font-size:3rem;}

.block-six{background:#e5e5e5;position:relative;text-align:center;padding:2rem 0}
.block-six h2{font-size:3rem;padding-bottom:.5rem;}
.block-six span{font-size:1.25rem;display:block;padding-bottom:2rem}
.block-six .col-md-4 img{margin-bottom:1.5rem; width: 100%; height: auto;}

.block-seven{background:url(../images/block-seven.jpg) top center no-repeat;min-height:100vh;background-size:cover;position:relative;color:#fff;text-align:center}
.block-seven h2{-webkit-text-stroke:#fff 1px;color:transparent;font-size:1.25rem;padding-top:80vh;}
.block-seven p{font-size:1rem;padding-top:.5rem}

.popup-fixed{position:fixed;height:100%;width:100%;z-index:999;background:rgba(0,0,0,.75);top:0;left:0;right:0;bottom:0}
.popup-container{position:absolute;height:100%;width:100%;z-index:9999;top:0;left:0;right:0;bottom:0; border-radius: 0 0 15px 15px;}
/*.popup-container-login {
  position: fixed; 
  border-radius: 15px; 
width: 90%;

}*/

.login-popup .row{margin-left:0;margin-right:0;}
.login-popup .row > *{padding-right:0;margin-left:0;margin-right:0}
.login-popup .shadow .row.relative{background:#fff;margin-top:90px;border-radius:var(--cm-border-radius);}
.login-popup img{width:100%;height:auto;border-radius:0 5px 5px 0}
.login-space{padding:1rem;margin:0;}

.login-popup h2{padding:1rem 0;font-size:2.5rem}
.login-popup h2+p{padding-bottom:3rem}
.login-popup .margin-bottom,
.Artwork-space .margin-bottom{margin-bottom:1rem}
.login-popup .margin-bottom span{display:block;padding-bottom:5px}

.close-popup{background:url(../images/icon-close-popup.svg) top center no-repeat;display:inline-block;width:19px;height:19px;position:absolute;right:10px;top:10px;cursor:pointer;z-index:9;}

.red,
.error{font-style:normal;color:red;font-size: 16px;}
.input{border:1px solid #707070;border-radius:var(--cm-border-radius);padding:5px;width:calc(100% - 10px);outline:none;transition:var(--cm-transition);color:#666}
.input:hover,
.input:focus{border-color:var(--cm-orange);}


.btn-submit,.comment-btn{width:calc(100% - 10px);padding:5px;margin-top:.5rem;color:#fff;background:#f57224;border:none;text-align:center;border-radius:var(--cm-border-radius);transition:var(--cm-transition);}
.btn-submit:hover,.comment-btn:hover,
.btn-submit:focus,.comment-btn:focus{background:#000;}

.comment-btn{width: auto;display: inline-block;padding: 5px 30px; margin-right: 10px;}

.comment-text{width: 100%; border: 1px solid #ccc;padding: 10px; height: 100px;}
.terms-space{padding:1rem}
.login-popup .terms-space h2 + p{padding-bottom:1.5rem;}
.overflow{height:300px;overflow-y:auto;border:1px solid #707070;padding:1.5rem}

.Agreement ol,
.Agreement ul{margin:0;padding:0}
.Agreement ol li,
.Agreement ul li{margin:0;padding:0 0 8px 0;font-size:.85rem}
.Agreement ol li p,
.Agreement ul li p{padding-bottom:5px}
.Agreement strong.strong{display:inline-block;padding-bottom:5px}
.Agreement p{padding-bottom:.75rem;font-size:.85rem}
.Agreement ol.decimal{list-style:decimal;margin-left:20px}
.Agreement ol.decimal ol li{list-style:lower-alpha}
.Agreement ol.decimal ol.lower-alpha li ul{margin-top:10px}
.Agreement ol.decimal ol.lower-alpha li ul li{list-style:disc;margin-left:10px}


 p{padding-bottom:1.5rem}
 
 .terms-space .form-check{border:1px solid #707070;border-top:none;padding:1rem 1.5rem;color:#333;font-weight:bold;font-size:.85rem}
 .terms-space .form-check-input{width:1.15em;height:1.15em;margin-left:0;margin-right:10px;vertical-align:top;display:inline-block;float:none}
 .terms-space .form-check-input:checked{background-color:#f57224;border-color:#707070;}
 
 .terms-space .form-check .form-check-label{display:inline-block;width:calc(100% - 2rem);vertical-align:top}
 
 


.terms-footer{text-align:right;padding-top:1rem}
.terms-footer a{display:inline-block;background:#f57224;padding:5px 0;min-width:200px;text-align:center;color:#fff;text-transform:uppercase;border-radius:var(--cm-border-radius);}
.terms-footer a.agree{background:#f57224;margin-bottom:1rem}
.terms-footer a.not-agree{background:#636363;}

.terms-footer a:hover,
.search-box:hover,
.search-box:focus{box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;outline:none}

.terms-footer a:hover,
.terms-footer a:active,
.terms-footer a:focus{color:#fff;}

.space{padding:8rem 0 0 0}

.listRow{padding-bottom:1.5rem}

.list-table{text-align:left}
.list-table .rs-table-row-header{background:#F57224;color:#fff;font-size:1.125rem;}
.list-table .rs-table-cell-content{height:auto !important;padding:10px 5px}
.list-table .table>:not(caption)>*>*{padding:0}

.list-table .rs-table-body-row-wrapper{background:#f3f3f3}
.list-table .rs-table-body-row-wrapper .rs-table-row{padding-left:12px;background:#E2E2E2;box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;}

.list-table .AlbumIcon,
.list-table .ArtWork,
.list-table .AudioIcon,
.list-table .ProofIcon,
.list-table .ViewIcon{width:15px !important;height:auto !important;margin:0 5px;}

.list-table .rs-table-body-row-wrapper .rs-table-row .rs-table-cell-wrap{margin-left:5px}

.list-table .rs-table-cell.rs-table-cell-last{border-right:none}

.list-table .rs-pagination-group{text-align:center;display:block !important;padding-top:50px;padding-bottom:50px}
.list-table .rs-pagination-group .rs-pagination-btn{border:1px solid #F57224;width:30px;height:30px;line-height:30px;border-radius:50%;}
.list-table .rs-pagination-group .rs-pagination-btn-active{background:#F57224;color:#fff}
.list-table .AudioIcon{width:22px !important;}
.add-new-album{display:inline-block;background:url(../images/icon-upload.svg) 15px center no-repeat #404040;padding:8px 15px 8px 3.75rem;text-align:center;/*width:225px;*/color:#fff;border-radius:var(--cm-border-radius);transition:var(--cm-transition);}
.add-new-album:hover{background:url(../images/icon-upload-hover.svg) 15px center no-repeat #f57224;box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;color:#fff}
.add-new-album2{background-image: none;padding-left: 15px;margin-left: 10px;}
.add-new-album2:hover{background-image: none;}
.unlock-new-album{display:inline-block;background:url(../images/unlock.svg) 20px center no-repeat #404040;padding:8px 15px 8px 3.75rem;text-align:center;/*width:225px;*/color:#fff;border-radius:var(--cm-border-radius);transition:var(--cm-transition); background-size: 30px; font-size: 20px;}
.unlock-new-album:hover{background:url(../images/unlock-back.svg) 20px center no-repeat #f57224;box-shadow:0 .5rem 1rem rgba(0,0,0,.15) !important;color:#fff; background-size: 30px;}


.search{width:300px;float:right;position:relative}
.search-box{border:1px solid #707070;border-radius:var(--cm-border-radius);width:100%;height:45px;padding-left:3rem}
.search-btn{border:none;width:50px;height:35px;background:url(../images/icon-search.svg) 10px center no-repeat;display:inline-block;vertical-align:top;position:absolute;top:5px}

.album-add{padding:1.5rem}
.album-add .input,
.album-add .btn-submit{text-align:center;padding:10px 5px;}

.album-add input[type="file"]{display:none;}
.album-add .custom-file-upload{border-radius:var(--cm-border-radius);padding:10px 5px;width:calc(100% - 10px);outline:none;transition:var(--cm-transition);cursor:pointer;background:#E0E0E0;color:#636363;display:block;text-align:center}
.album-add .custom-file-upload i{display:inline-block;background:url(../images/icon-upload-black.svg) left center no-repeat;vertical-align:middle;width:26px;height:21px;opacity:.75}
.album-add .custom-file-upload:hover{background-color:#999}

.album-add .btn-submit{width:225px;}

.ipr{padding:1rem 0 1rem 1rem;}
.login-popup .ipr h2{font-size:1.5rem}
.login-popup .ipr h2 + p{padding-bottom:1rem;}
.ipr ul{margin:0;padding:0;list-style:none}
.ipr ul li{padding:0;margin:0 0 10px 0;}
.ipr ul li p{color:#666;font-size:1rem;padding:0 2rem 0 0;margin:0}
.ipr ul li .note{background:#F0F0F0;padding:1.5rem;color:#666;font-size:1rem;border-radius:var(--cm-border-radius);margin-bottom:.5rem}
.ipr ul li i{font-style:normal;color:#666;font-size:14px;display:block}
.ipr ul li.align-right{width:200px;float: right;}
.ipr ul li:nth-child(10) span,
.ipr ul li:nth-child(13) span,
.ipr ul li:nth-child(16) span{display:block;background:url(../images/sep.png) left center repeat-x;height:2px}

.ipr ul li:nth-child(18){color:#666;}

.ipr .form-check-input{width:1.15em;height:1.15em;margin-left:0;margin-right:10px;vertical-align:middle;display:inline-block}
.ipr .form-check-input:checked{background-color:#f57224;border-color:#707070;}
.ipr ul li:nth-child(18) .input{display:inline-block;width:45%}

.ipr ul li:nth-child(20) .btn-submit{width:225px}

.ipr .input{padding:10px;width:calc(100% - 20px);}
.ipr .select{background:url(../images/icon-arrow-down.svg) 98% center transparent no-repeat;-webkit-appearance:none;-moz-appearance:none;}

.tab-view{margin:0;padding:0;list-style:none}
.tab-view li{display:inline-block}
.tab-view li.first a,
.tab-view li.second a{display:inline-block;padding:12px 25px;background:#F57224;border-radius:5px 5px 0 0;color:#fff;font-weight:bolder;min-width:160px}
.tab-view li.second a{background:#fff;color:#f57224}
.tab-view li.last{margin-top:5px;margin-bottom:5px;text-align:right;width:100%}
.alink{padding-bottom:1rem}
.alink a,
.alink .albumNameLink {display:inline-block;background:url(../images/icon-arrow-left.svg) right center no-repeat;padding:0 1.5rem 0 0;}
.alink .albumNameLink{background: none;}
.aduio-list .description-bg{background:#f8f8ff;border:1px solid #dee2e6;border-radius:var(--cm-border-radius);padding:1rem;margin-top:.25rem}
.description-bg .img-left{display:inline-block;vertical-align:top;width:150px}
.description-bg .img-left img{border-radius:var(--cm-border-radius)}
.description-bg .content-right{display:inline-block;vertical-align:top;width:calc(100% - 160px);}
.description-bg .content-right h3{font-size:1.15rem;border-bottom:1px solid #dee2e6;padding-bottom:0.5rem;margin-bottom:0.5rem;}
.description-bg .content-right p{padding-bottom:0}

.aduio-list .bordered-table{border-radius:var(--cm-border-radius);border:1px solid #dee2e6;margin:1rem 0;}
.aduio-list .table{border-radius:var(--cm-border-radius);margin-bottom:0}
.aduio-list .table > :not(:last-child) > :last-child > *{border-bottom-color: #dee2e6;}
.aduio-list .table th{background:#f8f8ff;}
.aduio-list .table td{border-right:1px solid #dee2e6}
.aduio-list .table tr td:last-child{border-right:none} 
.aduio-list audio{border-radius:var(--cm-border-radius);}

.album-add .custom-file-upload2{/*width:calc(90% - 10px);*/background:none;color:#636363;display:inline-block;border:1px solid #707070;}

.uploadTrack{display:inline-block;vertical-align:middle;background:url(../images/icon-upload-track.svg) center center no-repeat;cursor:pointer;width:26px;height:32px;margin-left:10px}
.uploadTrackMins{display:inline-block;vertical-align:middle;background:url(../images/icon-upload-track-minus.svg) center center no-repeat;cursor:pointer;width:26px;height:32px;margin-left:10px}

.tab-Artwork{margin:0;padding:0;list-style:none}
.tab-Artwork li{display:inline-block}
.tab-Artwork li.first a,
.tab-Artwork li.second a{display:inline-block;padding:12px 25px;border-radius:5px 5px 0 0;font-weight:bolder;min-width:160px}
.tab-Artwork li.first a{background:#fff;box-shadow:0 0 10px #ccc;}
.tab-Artwork li.last{float:right;margin-top:5px}

.bg-white{background:#fff;margin-left:0}

.Artwork-space{padding:1.5rem 1rem 1rem .75rem;font-size:15px}
.Artwork-space h2{font-size:1.25rem;margin:0;padding:0 0 1.25rem 0}
.Artwork-space label{display:inline-block;padding-bottom:5px;font-size:18px;}
.Artwork-space p{padding-bottom:.75rem}
.Artwork-space p label{padding-bottom:0}
.Artwork-space ul{margin:-5px 0 .75rem 1rem;padding:0}
.Artwork-space ul li{list-style:disc;padding-bottom:5px;}
.Artwork-space .select,
.list-table .select{background:url(../images/icon-arrow-down.svg) 98% center #fff no-repeat;-webkit-appearance:none;-moz-appearance:none;}
.Artwork-space .drag{border:1px solid #707070;padding:3rem 1rem;text-align:center;border-radius:var(--cm-border-radius);}
.Artwork-space .album-add{padding:0;margin:1rem 0}
.Artwork-space .album-add .custom-file-upload{background:#F57224;color:#fff;width:250px;margin:auto}
.Artwork-space .textarea{width:100%;height:100px;}
.captha{height:auto;width:300px}

.Artwork-space .btn-submit{padding:10px 15px;text-align:left;width: calc(100% - 30px);background:url(../images/icon-arrow-right.svg) 95% center #F57224 no-repeat;}
.Artwork-space .btn-submit:hover{background:url(../images/icon-arrow-right.svg) 95% center #000 no-repeat;}

.audio-wrapper.additional-class{margin-top:1rem;}
.loader-container{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, 0.5);display:flex;justify-content:center;align-items:center;z-index:9999999;}
.loader{border:4px solid #f57224;border-radius:50%;border-top:4px solid #ffffff;width:30px;height:30px;animation:spin 1s linear infinite;}

.btn-submit.disabled{background-color:#cccccc;color:#999999;cursor:not-allowed;}
.albumloader{color:#f57224;width:30px;height:30px;animation:spin 1s linear infinite;margin-left:10px;}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.albumCover{font-size:14px;color:#212529c9;}
label.verifylable{display:unset;}
.icon-delete{margin-left:10px;cursor:pointer;width:15px;height:15px;}
.fileDetail{font-size:14px;padding:2px;}

.brandImage{width: 200px !important; height: 200px !important;}

.filter-table .container .filter-row{background-color: #fff; padding: 1rem 0px 1rem 0px;box-shadow:0 0 10px #ccc;margin-left: 0px;margin-right:0px;border-radius: 10px;margin-bottom: 1rem;}
.filter-table .container .filter-row .btn-submit{margin: auto; width: 100px; margin-right: 1rem;}
.select-filter{background:url(../images/icon-arrow-down.svg) 98% center transparent no-repeat;-webkit-appearance:none;-moz-appearance:none;border: none;
  border-bottom: 1px solid #ccc;
  border-radius: unset;margin-bottom: 1rem;}
  .filter-btn {
    cursor: pointer;
    margin: 6px 10px 0px 0px;
    border: none;
}
.footer-container{background:#000;text-align:center;border-top:1px solid #1f3037;position:relative;z-index:99}
.footer{padding:1rem 0;color:#fff;}
.footer-center{border-top:1px solid #323232;padding-top:1rem;margin-top:1rem}
.footer a{color:#fff;}
.footer a:hover{color:#f05929;}
.footer-container img{width:300px;height:auto}

.icon-BACK{background:url() left center no-repeat;display:inline-block;color:#f05929;padding-left:35px;margin:10px 0;vertical-align:middle;font-weight:600;}
.icon-BACK:hover{padding-left:25px;}

.help-icon{margin-left:10px;display: inline-block;background: url(../images/icon-help.svg) left center; width: 26px; height: 30px; vertical-align: middle; cursor: pointer;}

@media screen and (max-width: 666px) {}

@media only screen and (min-width: 600px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {}

@media only screen and (max-width: 600px) and (orientation : portrait) {}

@media only screen and (min-width: 600px) and (orientation : landscape) {
.block-two .col-md-7{position:relative;height:100vh}
.block-two .absolute{position:absolute;top:50%;transform:translateY(-50%);}
.block-two .col-md-5{text-align:left}
.block-two video{height:300px;width:auto}	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
.block-one .col-md-6{width:100% !important}
.block-six img{width:100%;height:100%;margin-bottom:1rem}
.social-link{top:1.25rem}
}

@media only screen and (min-device-width : 810px) and (max-device-width : 1080px) and (orientation : portrait) {
.block-one .col-md-6{width:100% !important}
.block-six img{width:100%;height:100%;margin-bottom:1rem}
.social-link{top:1.25rem}
}

@media only screen and (min-device-width : 601px) and (max-device-width : 962px) and (orientation : landscape) and (min-device-height : 601px){}

@media only screen and (min-width: 768px) and (max-width: 1366px){}

@media only screen and (min-width: 1100px) {}

@media only screen and (max-width: 991px) {
.login-popup .row > *{padding-left:0;}
.listRow .col-md-6{text-align:center}
.listRow .col-md-6 .search{float:none;margin:10px auto 0 auto;}
}

@media only screen and (min-device-width : 834px) and (max-device-width : 1194px) and (orientation : portrait) and (min-device-height : 834px){


.block-two .col-md-7{position:relative;height:100vh}
.block-two .absolute{position:absolute;top:50%;transform:translateY(-50%);}
.block-two .col-md-5{text-align:left}
.block-two video{height:500px;width:auto}
 	
	
}


@media only screen and (min-width: 992px) {	
.header-left{width:100px}
.header-left img{width:75px;height:auto;margin:0}
.header-right{width:calc(100% - 100px);text-align:left;padding:1rem 0 0 0}
.header-right ul{margin:5px 0 0 0;padding:0;}
.navigation .header{text-align:center;}

.navigation .header img{width:auto; cursor: pointer;}
.navigation .header div.logout{margin-top:20px;float:right;}

.dropdown-content{top:60px;}
.menu-click,
.menu-close{display:none}

.menu-container{display:inline-block;background:none;position:relative;right:0;top:0;height:auto;z-index:99}
.menu-container ul{top:0;transform:translateY(0);text-align:left}
.menu-container li{padding:0 1rem 0 0;display:inline-block}

.social-link{top:1.15rem;right:0}
.social-link a{margin-right:.5rem}
.mailing{margin-bottom:0;display:inline-block;}
.social-link span{margin:0 .5rem}

.block-one{background-position:top center}
.block-one h1{padding:10rem 0 0 0;font-size:3rem}
.block-one h2{padding:2rem 0}
.block-one .relative:before{display:none}


.block-copyright h1{padding:8rem 0 2rem 0;font-size:3rem}
.block-copyright h2{padding:2rem 0}


.block-two{background-position:top center;text-align:left}
.block-two .col-md-7{position:relative;height:100vh}
.block-two .absolute{position:absolute;top:50%;transform:translateY(-50%);}
.block-two .col-md-5{text-align:left}
.block-two video{height:500px;width:auto}
.block-two h2{padding-top:0;font-size:6rem;letter-spacing:1px;}
.block-two h3{font-size:3.5rem;letter-spacing:1px;} 


.block-three{background-position:top center}
.block-three .align-center{padding-top:65vh}
.block-three h2{font-size:6.5rem;padding-bottom:.5rem;letter-spacing:1px;}
.block-three h3{font-size:2.5rem;letter-spacing:1px;}

.block-four{background-position:top center}
.block-four .align-center{padding-top:70vh}
.block-four h2{font-size:4.5rem;padding-bottom:.5rem;letter-spacing:1px;}
.block-four h3{font-size:6.5rem;letter-spacing:1px;}

.block-five{background-position:top center}
.block-five h2{font-size:3rem;letter-spacing:1px;padding-top:75vh;}
.block-five h3{font-size:6.5rem;letter-spacing:1px;}

.block-six{padding:4rem 0}
.block-six h2{font-size:5rem;padding-bottom:.5rem;}
.block-six span{font-size:2rem;padding-bottom:3rem}
.block-six .col-md-4 div{padding-top:5rem}
.block-six .col-md-4 img{margin-bottom:0}

.block-seven h2{font-size:2.15rem;letter-spacing:1px;padding-top:78vh;}
.block-seven p{font-size:1.5rem;padding-top:.5rem}

.login-space{padding:0 1.5rem 0 2rem;margin:0;position:absolute;top:50%;left:0;transform:translate(0, -50%);width:100%;height:80%}
.login-popup img{width:auto;}
.popup-container-fixed {
  width: 100%;
  margin: auto;
  top:auto;
  position: fixed;
  border-radius: 15px;
}
.terms-footer a.agree{/*margin-right:4rem;*/margin-bottom:0}

.terms-space{padding:1rem 2rem 2rem 2rem}
.terms-space.help-space{padding:2rem 2.5rem 0rem 2rem}
.album-add{padding:2.5rem 1.5rem 1.5rem 1.5rem}

.ipr{padding:1.5rem;}

.ipr ul li{display:inline-block;padding:0;margin:0 -15px 10px 0;vertical-align:top}

.ipr ul li:nth-child(1),
.ipr ul li:nth-child(2){width:45%;}

.ipr ul li:nth-child(3),
.ipr ul li:nth-child(5),
.ipr ul li:nth-child(8){width:30%;}

.ipr ul li:nth-child(4),
.ipr ul li:nth-child(9),
.ipr ul li:nth-child(12),
.ipr ul li:nth-child(11),
.ipr ul li:nth-child(15){width:40%;}

.ipr ul li:nth-child(6),
.ipr ul li:nth-child(7){width:45%;}

.ipr ul li:nth-child(10),
.ipr ul li:nth-child(13),
.ipr ul li:nth-child(16){width:77%}

.ipr ul li:nth-child(14){width:50%}

.ipr ul li:nth-child(17),
.ipr ul li:nth-child(18),
.ipr ul li:nth-child(19),
.ipr ul li:nth-child(20){width:99%}

.tab-view li.first a,
.tab-view li.second a{padding:12px 25px;min-width:175px}
.tab-view li.last{margin-top:-5px;float:right;width:auto}

.left-space{padding-left:0 !important}

.captha{width:auto}

.height{height:100%;}

.footer{padding:2rem 0;}
.footer-center{border-top:1px solid #323232;padding-top:2rem;margin-top:2rem}
.footer-container br{display:none}
.footer-container img{width:auto}

}




@media (min-width: 1800px) {
.block-two h2,
.block-two h3{padding-left:3rem}
}



/*-- XR iOS 12 --*/
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){}

/*-- XS Max iOS 12 --*/
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (min-device-height : 768px){}

@media only screen and (min-device-width : 810px) and (max-device-width : 1080px) and (orientation : landscape) and (min-device-height : 810px){}

@media (max-width:1200px) and (min-width:769px){}






.commentDev .input {
  width: 66%;
}

img.CommentIcon {
  margin-left: 10px;
}
.custom-tooltip {
  border: none;
  background: none;
}
.proofClass td{
  padding: 10px;
}
.proofContainer {
  text-align: center;
}
.videoBtn{
  background:url(../images/video.svg) center center no-repeat;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.video-container {
  padding-bottom: 40px;
}

.video-popup {
  margin-top: 90px;
}
.EditIconButton {
  border: none;
  background: none;
  padding: 0px 20px 0px 24px;
}
.commentDev {
  padding: 0px 23px 20px 35px;
}
.EditAudioButton{
  border: none;
  background: none;
  padding: 0px 20px 0px 33px;
}
.EditAudioButton:disabled {
  opacity: 0.5;
  cursor: not-allowed; 
}
button.DownloadButton {
  border: none;
  background: none;
}.progress-container {
  position: fixed;
  top: 25%;
  right: 121px;
  width: 300px; 
  background: #f3f3f3;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.progress-bar {
  height: 10px;
  background: #F57224;
  border-radius: 5px;
}

.downloadNotification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
}

.error-status-message {
  color: red;
  font-size: 14px;
  text-align: center;
}